// import Footer from '@/components/Footer';
import LucideIcon from '@/components/LucideIcon';
import RightContent from '@/components/RightContent';
import { getCurrentUser, getCurrentUserMenus } from '@/services/system';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
// import { SettingDrawer } from '@ant-design/pro-components';
// import { toCamelCase } from '@/utils/case-converter';
import { buildTree } from '@/utils/tree';
import type { RequestConfig, RunTimeLayoutConfig } from '@umijs/max';
import { history } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';

// 前端登录页面路由
const LOGIN_PATH = '/login';
// 后端登录接口
const API_LOGIN = '/api/system/login';

interface InitialState {
  settings?: Partial<LayoutSettings>;
  currentUser?: API.SystemUser;
  fetchUserInfo?: () => Promise<any>;
}

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<InitialState> {
  const fetchUserInfo = async () => {
    try {
      const result = await getCurrentUser({
        skipErrorHandler: true,
      });
      return result.data;
    } catch (error) {
      history.push(LOGIN_PATH);
    }
    return undefined;
  };

  // 如果不是登录页面，执行
  const { location } = history;
  if (location.pathname !== LOGIN_PATH) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings as LayoutSettings,
    };
  }

  return {
    fetchUserInfo,
    settings: defaultSettings as LayoutSettings,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  const { currentUser } = initialState as InitialState;
  const waterMark = `${currentUser?.name} ${String(currentUser?.mobile).slice(-4)}`;
  return {
    rightContentRender: () => <RightContent />,
    // footerRender: () => <Footer />,
    // collapsedButtonRender: false,
    bordered: false,
    onPageChange: () => {
      // 如果没有登录，重定向到 login
      if (!currentUser && history.location.pathname !== LOGIN_PATH) {
        history.push(LOGIN_PATH);
      }
    },

    waterMarkProps: {
      content: waterMark,
      fontSize: 14,
      fontColor: 'rgba(0, 0, 0, 0.08)',
    },

    menu: {

      request: async (params, defaultMenuData) => {
        console.log('defaultMenuData', defaultMenuData);

        const { data } = await getCurrentUserMenus();

        const menuData = data
          .filter((item: any) => item.showInMenu === '1')

        console.log('menuData', menuData);
        const tree = buildTree(
          menuData,
          (item: any) => item.menuId,
          (item: any) => item.parentId,
          (item: any) => ({
            value: item.menuId.toString(),
            title: item.name,
            key: item.menuId.toString(),
            hideInBreadcrumb: false,
            menuId: item.menuId.toString(),
            name: item.name,
            parentId: item.parentId?.toString(),
            type: item.type,
            icon: <LucideIcon name={item.icon as keyof typeof LucideIcon} size={16} />,
            path: item.path,
          }),
        );

        console.log('tree', tree);

        return tree;

        // // return defaultMenuData;
        // return [
        //   {
        //     path: '/',
        //     redirect: '/home',
        //   },
        //   {
        //     name: '首页',
        //     path: '/home',
        //     icon: <Home size={16} />,
        //   },
        //   {
        //     name: '系统设置',
        //     icon: <Bolt size={16} />,
        //     path: '/access',
        //   },
        //   {
        //     name: '系统管理',
        //     icon: <Settings size={16} />,
        //     path: '/system',
        //     children: [
        //       {
        //         path: '/system/users',
        //         name: '系统用户',
        //       },
        //     ],
        //   },
        // ];
      },
    },
    ...initialState?.settings,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request: RequestConfig = {
  ...errorConfig,
  requestInterceptors: [
    [
      (request: any) => {
        const authorization = localStorage.getItem('AUTHORIZATION');

        // 登录接口不需要 token
        if (authorization && request.url.indexOf(API_LOGIN) === -1) {
          request.headers.Authorization = authorization;
        }

        // 删除请求参数中的空值
        if (request.params && typeof request.params === 'object') {
          for (let key in request.params) {
            if (
              request.params[key] === undefined ||
              request.params[key] === null ||
              request.params[key] === ''
            ) {
              delete request.params[key];
            }
          }
        }

        return request;
      },
      (error: any) => Promise.reject(error),
    ],
  ],

  responseInterceptors: [
    [
      (response: any) => {
        if (Number(response.data.code) !== 0) {
          // 未登录
          if (Number(response.data.code) === -1001) {
            history.push(LOGIN_PATH);
          }

          return Promise.reject({
            code: Number(response.data.code),
            message: response.data.message,
          });
        }
        // 转换返回数据的 key 为驼峰
        // response.data = toCamelCase(response.data);
        return response;
      },
    ],
  ],
};
